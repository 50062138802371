export const GB_FEATS = {
  // Tooltips
  TTIP_REVIEW: 'proactive-tooltip-review',
  TTIP_LOYALTY: 'proactive-tooltip-loyalty-postpay',
  TTIP_ONBOARDING: 'proactive-tooltips-onboarding',
  // Popups
  PPUP_ONBOARDING: 'popup-how-it-works',
  PPUP_CASHBACK_HOME: 'loyalty-tooltip-home',
  PPUP_REGISTER_POSTPAY: 'popup-register-postpay',
  PPUP_REGISTER_NO_CASHBACK: 'popup-register-no-cashback',
  PPUP_BIG_TIPS: 'big-tips-popup',
  PPUP_SMALL_TIPS: 'small-tips-popup',
  // Discounts
  DISCOUNT_VISUALS_HOME: 'discounts-visuals-home',
  DISCOUNT_VISUALS_MENU: 'discounts-visuals-menu',
  DiSCOUNT_PROMO_C2P: 'discount-partner-c2p-mc',
  // Labels and copies
  BUTTON_LABELS_HOME: 'menu-buttons-keys',
  BUTTON_LABELS_PAY: 'pay-buttons-key',
  REGISTER_TITLES: 'register-title-keys',
  PLACEHOLDER_COMMENTS: 'placeholder-order-comments',
  // Misc.
  CHECK_GPS: 'check-gps-before-pay-later',
  SWIMLINES: 'product-swimlanes',
  PRODUCT_NOTES: 'product-notes',
  FONT_FAMILY: 'font-family',
  MENU_SEE_ALL: 'menu-see-all',
  REGISTER_BUTTONS: 'register-buttons',
  SPLIT_ACCOUNT: 'split-account',
  GENERAL_COMMENT_BOX: 'general-comment-box',
  // Archived
  TTIP_PROACTIVE: 'proactive-tooltips',
  GTM: 'google-tag-manager',
  SKIP_HOME: 'skip-home',

};

