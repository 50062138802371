import React from 'react';
import styled from "styled-components";

const TabPillContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: center;
  gap: 4px;
  width: 100%;
  background-color: ${({theme}) => theme.background};
  border: 1px solid ${({theme}) => theme.gray_50};
  border-radius: ${({theme}) => theme.radius_round};
  padding: 4px;
  box-shadow: ${({theme}) => theme.shadow_xs};
  box-sizing: border-box;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
`;

const TabLineContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid ${({theme}) => theme.text_a20};
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
`;

export const TabContainer = ({variant, ...props}: TabProps) => {
  if (variant === 'line') return <TabLineContainer {...props}/>;
  return <TabPillContainer {...props}/>;
};

export const Label = styled.label<{variant: 'pill' | 'line'}>`
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  color: ${({theme, variant}) => variant === 'line' ? theme.text_a50 : theme.gray_50};
  padding: ${({variant}) => variant === 'line' ? '4px 16px' : '8px 16px'} ;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
  z-index: 2;

  &:first-letter {
    text-transform: capitalize;
  }

  &:has(:checked) {
    color: ${({theme, variant}) => variant === 'line' ? theme.text : theme.background};
    font-weight: ${({variant}) => variant === 'pill' ? '600' : '500'};
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;;
  }
`;

const PillSlider = styled.div<{color?: string}>`
  position: absolute;
  top: 0;
  height: calc(100% - 8px);
  background-color: ${({theme, color}) => color || theme.primary};
  border-radius: ${({theme}) => theme.radius_round};
  margin: 4px 0;
  z-index: 1;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;

const LineSlider = styled.div<{color?: string}>`
  position: absolute;
  bottom: -2px;
  height: 2px;
  background-color: ${({theme, color}) => color || theme.primary};
  z-index: 1;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;

export const Slider = ({variant, ...props}: SliderProps) => {
  if (variant === 'line') return <LineSlider {...props}/>;
  return <PillSlider {...props}/>;
};

type TabProps = {
  disabled?: boolean
  variant: 'pill' | 'line'
  className?: string
  children: React.ReactNode
};

type SliderProps = {
  variant: 'pill' | 'line'
  color?: string
  style: React.CSSProperties
};