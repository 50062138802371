export const DEFAULT_REGISTER_TITLES = {
  register_title: 'register',
  register_subtitle: '',
  login_title: 'login',
  login_subtitle: '',
};

export const DEFAULT_PAY_LABELS = {
  pay_title: 'when do you want to pay',
  pay_now: 'pay now',
  pay_later: 'pay later'
};

export const DEFAULT_SPLIT_ACCOUNT = {
  active: true,
  amount: true,
  item: true,
  evenly: true,
  order: true,
};