import { Coords } from "../context/app";
import { customRound } from "../pages/checkout/functions/calculatePrices";

export function dispatchGeneralDiscountCents(generalDiscount: any, baseAmountCents: number, dispatch: any) {
    if (!generalDiscount || !generalDiscount.value || !generalDiscount.discount_type) {
        return;
    }

    const discountType = generalDiscount.discount_type;
    const value = generalDiscount.value;
    let maxDiscountCents = 0;

    if (discountType === 'percentage') {
        maxDiscountCents = Math.min(customRound((baseAmountCents * value) / 100), baseAmountCents);
    } else if (discountType === 'amount') {
        maxDiscountCents = Math.min(Math.round(parseFloat(value.toFixed(2)) * 100), baseAmountCents);
    }

    const generalDiscountCents = Math.min(maxDiscountCents, baseAmountCents);

    if (generalDiscountCents) {
        dispatch({
            type: 'UPDATE_CHECKOUT',
            payload: { generalDiscountCents },
        });
    }
};

export function dispatchCodeCoupon(codeCoupon: any, baseAmountCentsMinusGeneralDiscount: number, dispatch: any) {
    if (!codeCoupon || !codeCoupon.value || !codeCoupon.discount_type) {
        return;
    }
    dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: { codeCoupon }
    });
    const discountType = codeCoupon.discount_type;
    const value = codeCoupon.value;

    let maxDiscountCents = 0;

    if (discountType === 'percentage') {
        maxDiscountCents = customRound((baseAmountCentsMinusGeneralDiscount * value) / 100);
    } else if (discountType === 'amount') {
        maxDiscountCents = value * 100;
    }

    const codeCouponCents = Math.min(maxDiscountCents, baseAmountCentsMinusGeneralDiscount);

    if (codeCouponCents) {
        dispatch({
            type: 'UPDATE_CHECKOUT',
            payload: { codeCouponCents }
        });
    }
}

export const openGoogleMaps = ({lat, lng}: Coords, placeId?: string | null) => {
    if (lat === null || lng === null) return;
    let url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    if (placeId) {
        url += `&query_place_id=${placeId}`
    }

    window.open(url, '_system');
};

export const getImageUrl = (string: string | URL | undefined | null) => {
    if (!string || typeof string !== 'string') return '';

    const awsUrl = process.env.REACT_APP_AWS_S3_URL || '';
    const str = string.replace('https://None.s3.amazonaws.com', awsUrl);
    
    try {
        return new URL(str);
    } catch {
        const path = str.startsWith('/') ? str : '/' + str;
        const baseUrl = path.startsWith('/media') ? process.env.REACT_APP_BASE_URL : awsUrl;

        try {
            return new URL(baseUrl + path);
        } catch {
            console.error(new Error(`"${str}" is not a valid URL nor path`));
            return '';
        }
    }
};

export const getMaxViewportHeight = () => {
    const fixed = document.createElement("div");
    fixed.style.width = '1px';
    fixed.style.height = '100vh';
    fixed.style.position = 'fixed'
    fixed.style.left = '0';
    fixed.style.top = '0';
    fixed.style.bottom = '0';
    fixed.style.visibility = 'hidden';

    document.body.appendChild(fixed);

    const height = fixed.clientHeight;
    
    fixed.remove();

    return height;
};