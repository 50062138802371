import React, { ChangeEventHandler } from 'react';
import { Trans } from 'react-i18next';
import { SquareCheckbox } from '../checkbox';
import { ConsentsContainer } from './RegisterContainers';

const PRIVACY_POLICY = 'https://yumminn.com/politica-de-privacidad/';
const TERMS_AND_CONDITIONS = 'https://yumminn.com/condiciones-de-uso-usuarios/';

type Values = {
  lopd: boolean
  news: boolean
  [key: string]: any
};

type Props = {
  values: Values,
  handleChange: ChangeEventHandler
};

export const Consents = ({values, handleChange}: Props) => {
  const Link = ({href, children}: {href: string, children: React.ReactNode}) => (
    <a href={href} target='_blank' rel='noreferrer'>
      {children}
    </a>
  );

  return (
    <ConsentsContainer>
      <label htmlFor='lopd'>
        <SquareCheckbox
          name='lopd'
          value={values.lopd}
          onChange={handleChange}
        />
        <p>
          <Trans i18nKey={'terms_and_conditions_consent_label'}>
            I have read and accept the <Link href={PRIVACY_POLICY}>Privacy Policy</Link> and the <Link href={TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
          </Trans>
          <span>*</span>
        </p>
      </label>
      <label htmlFor='news'>
        <SquareCheckbox
          name='news'
          value={values.news}
          onChange={handleChange}
        />
        <p>
          <Trans i18nKey={'communications_consent_label'}>
            I agree to receive communications and discounts <b>(optional)</b>
          </Trans>
        </p>
      </label>
    </ConsentsContainer>
  );
};