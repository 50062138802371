import styled from "styled-components";

export const CounterContainer = styled.div<CounterProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  max-height: 1.5rem;

  button:first-of-type {
    opacity: ${({count}) => count ? 'auto' : 0};
  }
  
  span {
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    line-height: 1;
    text-align: center;
    color: currentColor;
    min-width: 2ch;
    opacity: ${({count}) => count ? 1 : 0};
  }
`;

export const CounterButton = styled.button`
  font-family: ${({theme}) => theme.typography};
  font-size: ${({theme}) => theme.font_sm};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.background};
  background-color: ${({theme}) => theme.text};
  border: none;
  border-radius: ${({theme}) => theme.radius_round};
  height: 1.7rem;
  width: 1.7rem;
  max-height: 1.7rem;
  max-width: 1.7rem;
  padding: 0.3rem;
  transition: opacity 0.15s ease-in-out;
  
  &:disabled {
    opacity: 0.4;
  }

  svg {
    height: 100%;
    width: 100%;
    margin: auto;
  }
`;

type CounterProps = {
  count: number
}