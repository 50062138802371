import React from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '../icons';


type Props = {
  style?: React.CSSProperties
}

export const PoweredByYumminn = ({style}: Props) => {
  return (
    <Container style={style}>
      <span>powered by</span>
      <Icon type={IconType.LogoColor}/>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  
  span {
    font-size: ${({theme}) => theme.font_xxs};
    color: ${({theme}) => theme.text};
  }
`;