import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { AppContext } from '../../../context/AppContext'
import { Icon, IconType } from '../../../elements'
import { calculateCashbackCents, calculateEuros, calculatePoints } from '../functions/calculatePrices'

export const RewardNotification = () => {
  const {
    state: {
      checkout: {
        tip: {
          amount
        },
        remaining,
        myTotal,
        loyaltyDiscountCents,
        zeroSixSelectedVoucher
      },
    }
  } = useContext(AppContext)
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((myTotal) *  zeroSixSelectedVoucher.value) / 100))) : 0
  const payment = Math.max((myTotal - amount) - loyaltyDiscountCents - zeroSixDiscountCents, 0);
  const points = calculatePoints(payment || remaining);
  const cashback = calculateCashbackCents(payment || remaining);
  const euros = calculateEuros(cashback);

  const [lastPayment, setLastPayment] = useState(0);
  const [animation, setAnimation] = useState<'coinIn' | ''>('');

  useEffect(() => {
    payment > lastPayment && setAnimation('coinIn');
    
    const timeout = setTimeout(() => {
      setAnimation('');
    }, 1000);

    setLastPayment(payment);

    return () => clearTimeout(timeout);
  }, [payment])

  return (
    <Badge
      type={cashback ? 'cashback' : 'points'}
      isShown={!!(cashback || points)}
      className={animation}
    >
      <Icon type={cashback ? IconType.CashbackColor : IconType.PointColor} size={18}/>
      <span>
        {cashback ? `+${euros}€` : `+${points}`}
        {!cashback && <Icon type={IconType.Yumminn}/>}
      </span>
    </Badge>
  );
};

const squeeze = keyframes`
  0% {
    scale: 1;
  }
  10% {
    scale: 1.1 0.9;
    margin-top: 2px;
  }
  40% {
    scale: 1.1 0.9;
    margin-top: 2px;
  }
  50% {
    scale: 0.9 1.1;
    margin-top: -2px;
  }
  60% {
    scale: 1.1 0.9;
    margin-top: 2px;
  }
  80%{
    scale: 0.95 1.05;
    margin-top: -1px;
  }
  90% {
    scale: 1.05 0.95;
    margin-top: 1px;
  }
  100% {
    scale: 1
  }
`;

const fall = keyframes`
  0% {
    translate: -50% -300%;
    opacity: 0;
  }
  100% {
    translate: -50% -50%;
    opacity: 1;
  }
`;

const Badge = styled.div<BadgeType>`
  position: absolute;
  top: 0;
  right: 0;
  translate: ${({isShown}) => isShown ? '60% -25%' : '60% 0%'};
  opacity: ${({isShown}) => isShown ? 1 : 0};

  span {
    scale: ${({isShown}) => isShown ? 1 : 0.5};
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: ${({theme}) => theme.font_xs};
    font-weight: 700;
    line-height: 1em;
    color: ${({theme}) => theme.background};
    background-color: ${({theme, type}) => type === 'cashback' ? theme.cash : theme.yumminn};
    border-radius: ${({theme}) => theme.radius_round};
    padding: 6px;
    z-index: 4;
    transition: 0.2s ease-in-out;
  }
  
  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -300%;
    opacity: 0;
  }

  &.coinIn {
    span {
      animation: ${squeeze} 0.6s 0.4s ease-in;
    };

    & > svg {
      animation: ${fall} 0.6s ease-in-out;
    }
  }
`;

type BadgeType = {
  type: 'cashback' | 'points'
  isShown: boolean
};