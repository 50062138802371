import React from 'react'
import { Icon, IconType } from '../icons'
import { PaymentLogos } from '../payment-logos'
import { FooterContainer } from './FooterContainers'

type Props = {
  showPaymentLogos?: boolean;
}

export const FooterComponent = ({showPaymentLogos}: Props) => {
  return (
    <FooterContainer>
      {showPaymentLogos &&
        <div>
          <PaymentLogos/>
        </div>
      }
      <div>
        Powered by
        <Icon type={IconType.Logo} size={16}/>
      </div>
    </FooterContainer>
  )
}