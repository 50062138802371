import React, { useContext, useState, createContext, useMemo } from "react";
import PropTypes, { InferProps } from "prop-types";
import { User } from "../app";
import isEqual from 'lodash/isEqual';

const INITIAL_USER = {
    id: null,
    name: '',
    lastName: '',
    email: '',
    phone: '',
    isVerified: false,
    points: 0,
    cashbackCents: 0,
    restaurantsVisited: 0,
    moneySavings: 0,
    timeSavings: 0,
    pointsDetails: [],
    loyaltyDetails: [],
    creditDetails: [],
    totalCredits: 0,
    geolocation: null,
    bumerangUserId: false,
    getInitials() {
            if(this.name && this.lastName){
                return (
                    this.name[0] + this.lastName[0]
                )
            } else {
                return this.name[0]
            }
    }
  };

  export type UserContextType = {
    user: User;
  };

  export type UserContextDispatchType = {
    updateUser: (data: Partial<User>) => void;
    removeUser: () => void;
  };

  export const UserContext = createContext<UserContextType>({
    user: INITIAL_USER,
  });

  export const UserContextDispatchContext =
    createContext<UserContextDispatchType>({
        updateUser: () => null,
        removeUser: () => null
    });

  export const useUserContextDispatchContext = () => useContext(UserContextDispatchContext);

  export const useUserContext = () => useContext(UserContext);
  function UserProvider({ children }: InferProps<typeof UserProvider.propTypes>) {
    const [user, setUser] = useState<User>(INITIAL_USER);
    const value = useMemo(() => ({user }), [user]);
  
    const updateUser = (data: Partial<User>) => {
      if (!isEqual(user, { ...user, ...data })) {
        setUser({ ...user, ...data });
      }
    }

    const removeUser = () => {
      setUser(INITIAL_USER);
    }
  
    return(
      <UserContext.Provider value={value}>
        <UserContextDispatchContext.Provider value={{ updateUser, removeUser }}>
          {children}
        </UserContextDispatchContext.Provider>
      </UserContext.Provider>
    );
  }
  
  UserProvider.propTypes = {
    children: PropTypes.object,
  };
  
  export default UserProvider;
  