import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useSwipe } from '../../shared/utils/useSwipe';
import { Label, Slider, TabContainer } from './TabSelectorContainers';

type Props = {
  name: string;
  value: any;
  options: string[];
  disabled?: boolean;
  color?: string;
  variant?: 'pill' | 'line'
  className?: string;
  onChange: React.Dispatch<React.SetStateAction<any>>;
};

export const TabSelectorComponent = ({name, value, options, disabled, color, variant = 'pill', className = '', onChange}: Props) => {
  const tabsRef = useRef<Array<HTMLLabelElement | null>>(new Array(options.length));

  const [{valueIndex, sliderWidth, sliderPosition}, setValues] = useState({ valueIndex: 0, sliderWidth: 0, sliderPosition: 0 });

  useEffect(() => {
    const valueIndex = options.indexOf(value);
    const sliderWidth = tabsRef.current[valueIndex]?.clientWidth || 0;
    const sliderPosition = tabsRef.current[valueIndex]?.offsetLeft || 0;

    setValues({valueIndex, sliderWidth, sliderPosition});
  }, [value, options]);

  const onSwipedLeft = () => {
    valueIndex > 0 && onChange(options[valueIndex - 1])
  };

  const onSwipedRight = () => {
    valueIndex < options.length - 1 && onChange(options[valueIndex + 1])
  };

  const swipeHandlers = useSwipe({onSwipedLeft, onSwipedRight})

  return (
    <TabContainer {...swipeHandlers} disabled={disabled} variant={variant} className={className}>
      <Slider variant={variant} color={color} style={{width: sliderWidth + 'px', left: sliderPosition + 'px'}}/>
      {options.map((option, index) => (
        <Label key={'option-' + option} variant={variant} ref={tab => tabsRef.current[index] = tab}>
          {option === 'credit' ? t('credits') : t(option)}
          <input
            type="radio"
            name={name}
            value={option}
            checked={value === option}
            onChange={(event: any) => onChange(event.target.value)}
            disabled={disabled}
          />
        </Label>
      ))}
    </TabContainer>
  );
};