import React from 'react';
import AppleLogin from 'react-apple-login';
import {Request as RegisterRequest}  from "../../api/register/Register.api";
import { LoginButton } from './login-buttons.component';
import { postError } from '../../shared/utils/postError';

type Props = {
  Render: (props: LoginButton) => JSX.Element
  from: string
  disabled?: boolean
  lopd: boolean
  news: boolean
  onSuccess: (data: any, type: string, from: string, isFirstLogin: boolean) => void;
  onFailure: (error: any) => void;
};


export const AppleButton = ({Render, from, lopd, news, onSuccess, onFailure}: Props) => {
  const restaurantId = sessionStorage.getItem('restaurantId');
  const paymentId = sessionStorage.getItem("paymentId") || "";
  const restaurantGroupUuid = sessionStorage.getItem('restaurantGroupUuid') || '';

  const appleResponse = (response: any) => {
    RegisterRequest.register({
      name: response.user ? response.user.name?.firstName : null,
      token: response?.authorization?.code,
      type: "apple",
      lopd,
      news,
      paymentId,
      restaurantId,
      restaurantGroupUuid,
      paymentSuccessfulPopup: from === 'pop-up_save-card',
    })
      .then((data: any) => onSuccess(data, "apple", from, lopd))
      .catch((error: any) => {onFailure(error); postError(error)});
  };

  const redirectURI = process.env.REDIRECT_APPLE_LOGIN_URL;

  return (
    <AppleLogin
      clientId="com.yumminn.yumminnapp.sid"
      redirectURI={redirectURI ? redirectURI : "https://webapp.yumminn.com/profile"}
      usePopup={true}
      callback={appleResponse}
      scope="email name"
      responseMode="form_post"
      responseType="code"
      render={(renderProps: any) => <Render type='apple' renderProps={renderProps}/>}
    />
  );
};