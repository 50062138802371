import React, { createContext, useReducer, Dispatch, useEffect, useMemo } from "react";
import { AppState, Checkout } from "./app";
import { appReducer, Action } from "./appReducer";
import { postError } from "../shared/utils/postError";
import { cloneDeep } from 'lodash';

const SAVE_CHECKOUT_KEYS = [ //values set in homeDispatcher
  'adyenClientKey',
  'adyenPlatform',
  'adyenMerchantAccount',
  'adyenAccountHolderCode',
  'adyenStoreCode',
  'adyenGooglePayCode',
  'clientCommission',
  'moneiPay',
  'moneiAccountId',
  'paymentMethods',
  'walletPaymentMethods',
  'loyaltyUnavailableCents',
  'generalDiscount',
  'paymentGateway'
];

const savedState = sessionStorage.getItem('appState');
sessionStorage.removeItem('appState');

const initialState: AppState = {
  lang: sessionStorage.getItem("language") || navigator?.language || navigator?.userLanguage || 'es',
  restaurantName: "",
  tpvId: 0,
  suggestionsId: 0,
  restaurantId: 0,
  tableNumber: "",
  restaurantCustomization: {
    theme: 'light',
    colors: {
      primary: '#094553',
      secondary: '#2C4942',
      tertiary: '#094553',
      photoContrastColor: '',
    },
    logoWidth: null,
    photoOverlay: true,
    showLoyalty: true,
    typography: "'Poppins', sans-serif",
  },
  restaurant: {
    id: 0,
    name: '',
    loyalty_program: false,
    loyalty_min_redeem_amount_cents: 0,
    companyName: '',
    companyCIF: '',
    isMockup: false,
    phoneCoordsWithinRadius: null,
    bizumPromotionCents: 0,
    masterC2pPromotionCents: 0,
    minMasterC2pPromotionAmountCents: 1500,
    external_menu: false,
    menu_link: '',
    menuType: 'STATIC',
    use_pay_by_item: true,
    country: "ES",
    show_menu_button: true,
    show_catalog_button: false,
    show_events_button: false,
    show_google_review_button: false,
    show_wallet_button: false,
    allow_reviews: true,
    allow_to_pay_table: true,
    allow_to_pay_now: true,
    allow_to_pay_later: false,
    allow_instant_orders: true,
    allow_program_orders: false,
    smsEnabled: true,
    preparationMinutes: 0,
    address: "",
    location: {lat: null, lng: null},
    restaurantGooglePlaceId: null,
    isLoginRequired: false,
    wifiData: null,
  },
  checkout: {
    tip: {
      selected: '',
      amount: 0,
    },
    allModifierCategories: [],
    allProductCategories: [],
    suggestionsCategory: null,
    allDishesOrder: [],
    selectedModifiers: [],
    selectedItems: [],
    orderAndPayItems: [],
    tempOrderAndPayItems: [],
    productList: [],
    selectedProduct: null,
    extrasChosen: [],
    orderItems: [],
    leftTopay: 0,
    remaining: 0,
    myTotal: 0,
    tempTotal: 0,
    customerName: '',
    customerVAT: '',
    totalBill: 0,
    paymentMethod: '',
    savedCards: [],
    otherCardSelected: false,
    paymentSplitType: 'all',
    orderId: 0,
    otherPayments: [],
    adyenMerchantAccount: "",
    adyenPlatform: "",
    adyenClientKey: "",
    adyenAccountHolderCode: "",
    adyenStoreCode: "",
    adyenGooglePayCode: "",
    availableLoyaltyCents: 0,
    availableCreditCents: 0,
    termsAndConditionsLink:  "",
    legalNoticeLink: "",
    privacyPolicyLink: "",      
    zeroSixInfo: {
      points_balance: null,
      points_total: null,
      vouchers: []
    },
    zeroSixSelectedVoucher: {
      name: "",
      code: "",
      value: 0,
      is_percentage: false,
      expiration: "",
      min_amount: 0
    },
    loyaltyDiscountCents: 0,
    loyaltyUnavailableCents: 0,
    generalDiscountCents: 0,
    productDiscountCents: 0,
    generalDiscount: null,
    codeCouponCents: 0,
    codeCoupon: null,
    credit: null, 
    creditCents: 0,
    clientCommission: 0,
    deliveryFeeCents: 0,
    deliveryFeeCentsPerKm: 0,
    deliveryFeeMinCents: 0,
    cardTokenized: false,
    moneiPay: false,
    moneiAccountId: "",
    paymentGateway: "monei",
    paymentMethods: [],
    walletPaymentMethods: [],
    roadmap: {
      step: 0,
      progress: 0
    },
    checkoutFlow: 'PAT',
    selectedSlot: null,
    splitEvenlyPeopleNumber: 2,
    ...(savedState ? JSON.parse(savedState) : {})?.checkout
  },
  addressData: {
    locality: "",
    street: "",
    streetNumber: "",
    floorAndDoor: "",
    delivery_notes: ""
  },
  wallet: {
    balanceCredit: 0,
    extraCredits: 0,
    bonusAmount: 0,
    bonusType: "percentage"
  },
  ...(savedState ? JSON.parse(savedState) : {})?.global
};

const savedContextCheckout = localStorage.getItem('yumminn_context')
const savedContextCheckoutParsed = savedContextCheckout ? JSON.parse(savedContextCheckout) as Checkout: null;

const stateHandler = () => {
  if(!!savedContextCheckoutParsed && (window.location.href.includes("payment-successful") || window.location.href.includes("webview-android-gpay"))){
    localStorage.removeItem('yumminn_context')
    initialState.checkout = savedContextCheckoutParsed
  } else if(!!savedContextCheckoutParsed && window.location.href.includes("payment-error")){
    localStorage.removeItem('yumminn_context')
    try {
      localStorage.setItem('contextCheckout', JSON.stringify(initialState.checkout))
    } catch (err: any){
      postError(err)
    }
    initialState.checkout = savedContextCheckoutParsed
  }

  return initialState;
};

const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch<Action>;
}>({
  state: stateHandler(),
  dispatch: () => null
});

type AppContextProviderProps = {
  children: React.ReactNode,
};

const AppContextProvider = ({
  children
}: AppContextProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(appReducer, stateHandler());

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    const saveContext = () => {

      try {
        const stateCopy = cloneDeep(state) as Partial<AppState>;

        delete stateCopy.checkout;
  
        const checkoutCopy = {} as Partial<Checkout>;
  
        for (const key in state.checkout) {
          if (SAVE_CHECKOUT_KEYS.includes(key)) {
            checkoutCopy[key as keyof Checkout] = state.checkout[key as keyof Checkout]
          }
        }
  
        const context = JSON.stringify({global:stateCopy, checkout: checkoutCopy});
        sessionStorage.setItem('appState', context);
      } catch (error: any){
        postError(error)
        console.log(`Error AppContext: ${error}`)
      }
     
    };

    window.addEventListener('beforeunload', saveContext);

    return () => window.removeEventListener('beforeunload', saveContext);
  }, [state]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider, initialState }
