import React from 'react';
import { MinusButton, PlusButton } from '.';
import { CounterContainer } from './CounterContainers';

type Props = {
  count: number
  min?: number
  max?: number
  disabled?: boolean
  disableAdd?: boolean
  disableSubtract?: boolean
  onAdd: React.MouseEventHandler
  onSubtract: React.MouseEventHandler
};

export const CounterComponent = ({count, min = 0, max, disabled,  disableAdd, disableSubtract, onAdd, onSubtract}: Props) => {
  return (
    <CounterContainer count={count}>
      <MinusButton disabled={disabled || disableSubtract || min >= count} onClick={onSubtract}/>
      <span>{count}</span>
      <PlusButton disabled={disabled || disableAdd || !!max && max <= count} onClick={onAdd}/>
    </CounterContainer>
  );
};