import { Allergens, AllergenLabels } from "../../context/app";

export const ALLERGENS_LIST: {value: Allergens, label: AllergenLabels, color: string}[] = [
  {value: 1, label: "Eggs", color: "#D2B160"},
  {value: 2, label: "Lupin", color: "#E8D470"},
  {value: 3, label: "Gluten", color: "#D2743E"},
  {value: 4, label: "Sesame", color: "#9A8E6C"},
  {value: 5, label: "Mustard", color: "#DEA31B"},
  {value: 6, label: "Fish", color: "#297F97"},
  {value: 7, label: "Celery", color: "#8BA739"},
  {value: 8, label: "Crustaceans", color: "#984A3D"},
  {value: 9, label: "Peanuts", color: "#B3976F"},
  {value: 10, label: "Molluscs", color: "#666666"},
  {value: 11, label: "Soy", color: "#91A05C"},
  {value: 12, label: "DriedFruit", color: "#784E3C"},
  {value: 13, label: "Sulphites", color: "#0269AE"},
  {value: 14, label: "Milk", color: "#64397E"},
];