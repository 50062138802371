import { OrderItem } from "../../context/app";

export type OrderInfo = {
  newOrder: boolean;
  orderCode: string;
  orderGroupCode: string;
  orderGroupCreatedAt: number;
  hasBeenSentToKitchen: boolean;
  orderAndPayItems: any[];
  restaurantId: string;
  tableNumber: string;
  noExistingProducts: boolean;
}

export function setOrderInfo(orderInfo: OrderInfo) {
  sessionStorage.setItem("orderInfo", JSON.stringify(orderInfo));
}

export function getOrderInfo() {
  const orderInfoSessionStorage = sessionStorage.getItem("orderInfo")
  const orderInfo: OrderInfo | null = orderInfoSessionStorage ? JSON.parse(orderInfoSessionStorage) : null;
  return orderInfo
}

export function setOrderSentToKitchen() {
  const orderInfo = getOrderInfo();
  sessionStorage.setItem("orderInfo", JSON.stringify({ ...orderInfo, hasBeenSentToKitchen: true }))
}

export function removeOrderInfo(restaurantId: any, tableNumber: any) {
  const orderInfo = getOrderInfo();
  if (orderInfo?.tableNumber !== tableNumber && orderInfo?.restaurantId !== restaurantId) {
    sessionStorage.removeItem("orderInfo");
  }
}

export function getBillItemsThatMatchOrderInfo(billItems: any[]) {
  const orderInfo = getOrderInfo();
  if (!orderInfo) return

  const orderInfoList: any[] = []

  orderInfo.orderAndPayItems.forEach((orderAndPayItem: any) => {
    orderAndPayItem.forEach((item: any) => {
      orderInfoList.push({
        "name": item.name,
        "price": parseInt(item.price)
      })
    })
  })

  const finalList: any[] = []

  orderInfoList.forEach((orderInfoItem: any) => {
    if (billItems && billItems.filter((billItem: any) => orderInfoItem.name === billItem.name).length > 0) {
      const billItemsAux= billItems.filter((billItem: any) => orderInfoItem.name === billItem.name)

      // check if there are more than one billItem with the same name and if there are, check if any of them is fully paid and not add it to the final list
      if(billItemsAux.length > 1){
        billItemsAux.forEach((billItem: any) => {
          if(!billItem.fully_paid){
            finalList.push(billItem)
            return;
          }
        })  
      }else{
        finalList.push(billItemsAux[0])
      }
    }
  })
  return finalList
}
