import { t } from 'i18next';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Coords, Payment, Receipt, Restaurant } from '../../../context/app';
import { AuthContext } from '../../../context/auth/AuthContext';
import { calculateEuros, calculateOrderTotal, calculateTotal } from '../../../pages/checkout/functions/calculatePrices';
import { SolidSeparator } from '../../../pages/payment-successful/PaymentSuccessfulContainers';
import { Icon, IconType } from '../../icons';
import { ProductDetailList } from '../../product-details-list';
import { SendReceiptInput } from '../../send-receipt-input';
import { ReceiptDetailsContainer, TicketContainer } from './ReceiptsContainers';
import { useHistory } from 'react-router-dom';
import { PickUpCard } from '../../../pages/payment-successful/pick-up-card/PickUpCard';
import { AppContext } from '../../../context/AppContext';
import { openGoogleMaps } from '../../../shared/functions';
import { Button } from '../../buttons';
import { ButtonVariant } from '../../buttons/enums';
import { STATUS_BG, STATUS_EMOJIS } from './constants';
import { Scrollbar } from '../../scrollbar';
import { PriceBadge } from '../../../pages/checkout/components/MyTotal';

type Props = {
  receipt: Receipt | Payment
  fullPage?: boolean
  closeTicket?: () => void
};

type AddressData = {
  addressLabel: 'shipping_address' | 'pickup_address' | null
  address: string | null,
  location: Coords,
  placeId: string | null,
};

const getAddressData = (functionality: string, restaurant: Restaurant, receipt: Receipt | Payment) => {
  let addressData: AddressData = {
    addressLabel: null,
    address: null,
    location: {lat: null, lng: null},
    placeId: null,
  };

  if (functionality === 'delivery') {
    const latLng = receipt.delivery_address_coord ? receipt.delivery_address_coord.split(', ') : [null, null];

    addressData = {
      addressLabel: 'shipping_address',
      address: receipt.delivery_address,
      location: {lat: Number(latLng[0]) || null, lng: Number(latLng[1]) || null},
      placeId: null,
    };
  }

  if (functionality === 'takeaway') {
    addressData = {
      addressLabel: 'pickup_address',
      address: restaurant.address,
      location: restaurant.location,
      placeId: restaurant.restaurantGooglePlaceId,
    };
  }

  return addressData;
};

export const ReceiptTicket = ({receipt, fullPage, closeTicket}: Props) => {
  const { isLogged } = useContext(AuthContext);

  const {
    state: {
        restaurant,
        checkout: {
          generalDiscount
        }
      }
  } = useContext(AppContext);

  const history = useHistory();

  const functionality = sessionStorage.getItem('functionality') || '';
  const restaurantName = sessionStorage.getItem('restaurantName');
  const logo = receipt?.restaurant_logo || sessionStorage.getItem('restaurantLogo');
  
  const isTable = ['just_pay', 'order_at_table'].includes(functionality);
  const isPaid = 'base_amount_cents' in receipt;

  const {
    addressLabel,
    address,
    location,
    placeId,
  } = getAddressData(functionality, restaurant, receipt);

  const isPaymentSuccessful = history.location.pathname === '/payment-successful';
  const transContext = isTable ? 'table' : 'collect';
  
  const date = moment(receipt.date).format('DD/MM/YYYY H:mm');
  const status = !isPaid ? 'order_placed' : (receipt?.order_status || receipt.status);
  const orderCode = receipt?.order_code || (receipt as Payment)?.order_request
  const accountTotal = !isPaid ? calculateOrderTotal(receipt) : calculateTotal(receipt);

  const pickUpRange = receipt.order_pickup_time
    ? `${moment(receipt.order_pickup_time).format('H:mm')} - ${moment(receipt.order_pickup_time).add(15, 'm').format('H:mm')}`
    : null;

  const showPickUpRange = !isTable && pickUpRange;

  const [isShown, setIsShown] = useState(false)
  
  const ticketRef = useRef<HTMLDivElement>(null);

  const seeGoogleMaps = () => {
    if (location.lng === null || location.lng === null) return;
    openGoogleMaps(location, placeId);
  };

  const animateClose = () => {
    setIsShown(false);
    setTimeout(() => {
      closeTicket && closeTicket();
    }, 400);
  };

  useEffect(() => {
    setIsShown(true)

    const closeReceipt = (event: TouchEvent) => {
      const childNodes = ticketRef.current?.childNodes as NodeList;
      const isTicket = Array.from(childNodes).some(node => node.contains(event.target as Node))

      !isTicket && animateClose();
    }

    window.addEventListener('touchstart', closeReceipt);

    return () => window.removeEventListener('touchstart', closeReceipt)
  }, []);

  return (
    <TicketContainer ref={ticketRef} isShown={isShown} fullPage={fullPage}>
      <header>
        {!orderCode &&
          <>
            {logo &&
              <img src={`${process.env.REACT_APP_AWS_S3_URL}/${logo}`}/>
            }
            <div>
              <h3>{receipt?.restaurant_name || restaurantName}</h3>
              <span>{date}</span>
            </div>
          </>
        }
        {!!orderCode &&
          <div>
            <h3>
              <p style={{fontSize: "24px", fontWeight: 400}}>{t('order') + ':'}</p>
              <b style={{fontSize: "24px"}}>{'#' + orderCode}</b>
            </h3>
            <h3>{receipt?.restaurant_name || restaurantName}</h3>
            <span>{date}</span>
            {(showPickUpRange && isPaid) &&
              <p className='ready_at'>
                {t('pickup_time') + ': '}
                <b>🕑 {pickUpRange}</b>
              </p>
            }
            <p className='status'>
              {t('status') + ': '}
              {STATUS_EMOJIS[status]}
              <span style={{backgroundColor: STATUS_BG[status]}}>
                {t(status, {context: transContext})}
              </span>
            </p>
            {(status === 'ready_to_pick_up' && receipt?.locker_name) &&
              <p className='locker'>
                {t('locker') + ': '}
                <span className='name' style={{backgroundColor: STATUS_BG[status]}}>
                  {receipt.locker_name}
                </span>
                {receipt?.locker_key &&
                  <span className='key'>🔑{receipt.locker_key}</span>
                }
              </p>
            }
          </div>
        }
        {!!closeTicket &&
          <button onClick={animateClose}>
            <Icon type={IconType.Close}/>
          </button>
        }
      </header>
      <Scrollbar maxHeight='calc(100vh - 200px)'>
        <ReceiptDetailsContainer>
          {(address && addressLabel) &&
            <div className='address'>
              <b>{t(addressLabel)}</b>
              {address}
              <Button
                variant={ButtonVariant.Link}
                title={`📍${t('see in maps')}`}
                handleClick={seeGoogleMaps}
              />
            </div>
          }
          {!isPaymentSuccessful && receipt?.order_uuid &&
            <PickUpCard orderCode={orderCode} orderUuid={receipt?.order_uuid}/>
          }
          <ProductDetailList receipt={receipt}/>
          {!!receipt.delivery_fee_cents &&
            <div>
              <p>{t('delivery_fee')}</p>
              <p>{calculateEuros(receipt.delivery_fee_cents) + '€'}</p>
            </div>
          }
          {!!receipt.tip_cents &&
            <div>
              <p>{t('tip')}</p>
              <p>{calculateEuros(receipt.tip_cents) + '€'}</p>
            </div>
          }
          {(!!receipt.loyalty_discount_cents || !!receipt.zerosix_discount_cents) &&
            <div className='discount'>
              <p>{t('discount')}</p>
              <p>{'-' + calculateEuros(receipt.loyalty_discount_cents + receipt.zerosix_discount_cents) + '€'}</p>
            </div>
          }
          {!!receipt.general_discount_cents &&
            <div className='discount'>
              <p>{generalDiscount?.marquee_text || t('discount')}</p>
              <p>{'-' + calculateEuros(receipt.general_discount_cents) + '€'}</p>
            </div>
          }
              {!!receipt.product_discount_cents &&
            <div className='discount'>
              <p>{t('product_discount')}</p>
              <p>{'-' + calculateEuros(receipt.product_discount_cents) + '€'}</p>
            </div>
          }
          {!!receipt.code_coupon_cents &&
            <div className='discount'>
              <p>{t('Coupon discount')}</p>
              <p>{'-' + calculateEuros(receipt.code_coupon_cents) + '€'}</p>
            </div>
          }
          {!!receipt.credit_cents &&
            <div className='discount'>
              <p>{t('Credit cents')}</p>
              <p>{'-' + calculateEuros(receipt.credit_cents) + '€'}</p>
            </div>
          }
          {!!receipt.bizum_promotion_cents &&
            <div className='discount'>
              <p>{t('promo_bizum')}</p>
              <p>{'-' + calculateEuros(receipt.bizum_promotion_cents) + '€'}</p>
            </div>
          }
          {!!receipt.master_c2p_promotion_cents &&
            <div className='discount'>
              <p>{t('promo_c2p')}</p>
              <p>{'-' + calculateEuros(receipt.master_c2p_promotion_cents) + '€'}</p>
            </div>
          }
          <div className='total'>
            <p>{t('account total')}</p>
            <PriceBadge type='total'>
              {calculateEuros(accountTotal) + '€'}
            </PriceBadge>
          </div>
          {isLogged && !!receipt.loyalty_cents &&
            <div>
              <p>{t('cashback')}</p>
              <b style={{fontWeight: 600}}>
                {calculateEuros(receipt.loyalty_cents) + '€'}
                <Icon type={IconType.CashbackColor}/>
              </b>
            </div>
          }
          {('base_amount_cents' in receipt && status !== "pending_cash") &&
            <>
              <SolidSeparator/>
              <SendReceiptInput paymentId={receipt.id} restaurantId={receipt.restaurant_id} from={'home'}/>
            </>
          }
        </ReceiptDetailsContainer>
      </Scrollbar>
    </TicketContainer>
  )
}