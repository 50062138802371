import React, { useEffect, useContext, useState } from "react";

import "./HeaderBar.scss";

import { PopupType } from "../../context/popups.enum";
import { AppContext } from "../../context/AppContext";
import { eventTrack } from "../../../useGaTracker";
import { useHistory } from "react-router-dom";
import { LanguageMenu } from "../language-menu";
import { SavedButton } from "../saved-button";
import { calculateEuros } from "../../pages/checkout/functions/calculatePrices";
import { Icon, IconType } from "../icons";
import { useSavedButton } from "../saved-button/useSavedButton";
import { ReceiptsWidget } from "./receipts-widget/ReceiptsWidget";
import { IconsContainer } from "../main-image/MainImageContainers";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { getProgramsFromStorage } from "../../pages/checkout/functions/getProgramsFromStorage";
import { UserContext } from "../../context/auth/UserContext";
import { HeaderBarContainer, HeaderInnerContainer, InitialsContainer, PointsCounter, ProfileButton, ProfileHeader } from "./HeaderBarContainer";
import { AuthContext } from "../../context/auth/AuthContext";
import { useInitialUserData } from "../../shared/utils/useInitialUserData";
import miniBumerang from "../../assets/images/bumerang_mini.svg";
import { useRestaurantData } from "../../shared/utils/useRestaurantData";
import { useFeature } from "@growthbook/growthbook-react";
import { GB_FEATS } from "../../growthbook";

type props = {
  link?: string
  noHome?: boolean
  instagram?: string
  isCatalog?: boolean
  showBackButton?: boolean
  setShowCatalog?: React.Dispatch<React.SetStateAction<boolean>>
  setShowSplashScreen?: React.Dispatch<React.SetStateAction<boolean>>
}

const RESTAURANT_PAGES = ['/checkout', '/event_catalog'];
const PROFILE_PAGES = ['/profile', '/loyalty-cards', '/loyalty-details', '/loyalty-points', '/register', '/my-account', '/forgot-password'];

export const HeaderBar = ({link, instagram, isCatalog, noHome = false, showBackButton = true, setShowCatalog, setShowSplashScreen} : props) => {
  const {
    state: {
      restaurant: {
        wifiData,
        show_wallet_button
      },
      checkout: {
        myTotal,
        availableLoyaltyCents,
        zeroSixInfo
      },
      restaurant: {
        bumerang,
      },
      wallet: {
        balanceCredit
      }
    },
    dispatch
  } = useContext(AppContext);

  const { isLogged } = useContext(AuthContext);
  const { user } = useContext(UserContext);

  const { restaurantId, tableNumber, restaurantGroupUuid } = useRestaurantData();
  const loginButtons = useFeature(GB_FEATS.REGISTER_BUTTONS).value;
  let atLeastOneLoginAvailable = true
  if (loginButtons) {
    atLeastOneLoginAvailable = Object.values(loginButtons).some(value => value === true);
}  
  const deliTakeUuid = sessionStorage.getItem('deliTakeUuid') || "";
  const qrUuid = sessionStorage.getItem('qrUuid') || "";
  const functionality = sessionStorage.getItem('functionality') || '';

  const paramsProxy: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });
  const history = useHistory();
  const path = location.pathname;
  const {goToPopup, closePopup} = usePopupNavigation();
  const {loyaltyProgram, pointsProgram} = getProgramsFromStorage();
  const {isFavorite, isLoading, onSubmit} = useSavedButton();
  const updateInitialUserData = useInitialUserData()

  const allowToPayTable = JSON.parse(sessionStorage.getItem('allowToPayTable') ?? "false");
  const allowBooking = JSON.parse(sessionStorage.getItem('allowBooking') ?? "false");
  const thereIsHome = !noHome


  const defaultGoBack = () => {
    let pathToGo = '/profile';
    if (paramsProxy?.goBack == "true") {
      history.goBack();
    } else {
      if (link) {
        pathToGo = link;
      } else if (restaurantGroupUuid && functionality !== 'delivery') {
        pathToGo = `/restaurant_group/${restaurantGroupUuid}`;
      } else if (restaurantId && tableNumber && !allowBooking) {
        pathToGo = `/home?id=${restaurantId}&table=${tableNumber}`;
      } 
      if (deliTakeUuid && functionality === 'delivery') {
        pathToGo = `/delivery_takeaway/${deliTakeUuid}`;
      }
    }
    history.push(pathToGo, {from : path});
  };

  const goBackCatalogHeader = () => {
    setShowSplashScreen && setShowSplashScreen(false)
    closePopup()
    setShowCatalog && setShowCatalog(!isCatalog)
  };

  const handleProfileClick = () => {
    goToPopup(PopupType.Register);
  };

  const handleCounterClick = () => {
    eventTrack("click loyalty counter on header DA", "click-loyalty-counter-on-header-DA", "Click on loyalty counter DA in homepage")

    !isLogged && goToPopup(PopupType.Register, {openBy: 'loyalty'});
  };

  useEffect(() => {
    isLogged && updateInitialUserData();
  }, [isLogged]);

  if (PROFILE_PAGES.includes(path)) return (
    <ProfileHeader showBackButton={showBackButton}>
      <span className='back_button' onClick={defaultGoBack}>
        <Icon type={IconType.Arrowback}/>
      </span>
      {path !== '/register' &&
        <Icon type={IconType.LogoWhite} size={24}/>
      }
      <LanguageMenu noHome={noHome}/>
    </ProfileHeader>
  )

  const notInitialScreen = isCatalog
  const redirection = '?redirection=' + `webapp.yumminn.com/${qrUuid}`

  return (
    <>
      <HeaderBarContainer id='headerContainer' path={path}>
        <HeaderInnerContainer>
          {/*back button*/}
          {RESTAURANT_PAGES.includes(path) && (!!restaurantGroupUuid && functionality !== 'delivery') ?
            <IconsContainer onClick={() => myTotal !== 0 ? goToPopup(PopupType.ExitCheckout) : history.push('/restaurant_group/' + restaurantGroupUuid)}>
              <Icon type={IconType.Arrowback}/>
            </IconsContainer>
          : (thereIsHome && notInitialScreen) || !!deliTakeUuid &&
            <IconsContainer onClick={allowToPayTable ? goBackCatalogHeader : defaultGoBack}>
              <Icon type={IconType.Arrowback}/>
            </IconsContainer>
          }

          {/* wifi */}
          {wifiData &&
            <button onClick={() => goToPopup(PopupType.WifiData)}>
              <Icon type={IconType.Wifi}/>
            </button>
          }

          {/*instagram*/}
          {instagram &&
            <button onClick={() => window.open(instagram, "_blank")}>
              <Icon type={IconType.Instagram} size={24}/>
            </button>
          }

          {/*favorite button*/}
          {RESTAURANT_PAGES.includes(path) && atLeastOneLoginAvailable &&/*!isCatalog &&*/
            <SavedButton state={isFavorite} isLoading={isLoading} handleClick={onSubmit}/>
          }
        
          {/*receipts button*/}
          {RESTAURANT_PAGES.includes(path) &&
            <ReceiptsWidget/>
          }

        </HeaderInnerContainer>

        <HeaderInnerContainer>

          {/*points button*/}
          {pointsProgram &&
            <PointsCounter onClick={handleCounterClick}>
              <Icon type={IconType.PointColor} size={18}/>
              <span>{user.points}</span>
            </PointsCounter>
          }

          {/*cents button*/}
          {loyaltyProgram &&
            <PointsCounter onClick={handleCounterClick}>
              <Icon type={IconType.CashbackColor} size={18}/>
              <span>{calculateEuros(availableLoyaltyCents) + '€'}</span>
            </PointsCounter>
          }

          {/*credits button*/}
          {show_wallet_button && 
            <PointsCounter type='credit' onClick={() => history.push({pathname: '/loyalty-cards', state: {type: 'credit'}})}>
              <Icon type={IconType.CreditBanner} size={18}/>
              <span>{calculateEuros(balanceCredit) + '€'}</span>
            </PointsCounter>
          }

          {/*zeroSix button*/}
          {zeroSixInfo?.points_balance !== null && zeroSixInfo?.points_balance !== undefined &&
            <PointsCounter onClick={() => history.push('/zerosix-cards')}>
              <Icon type={IconType.ZerosixColor} size={18}/>
              <span>{zeroSixInfo.points_balance}</span>
            </PointsCounter>
          }

          {/*account button*/}
          {atLeastOneLoginAvailable ? (
            isLogged ? (
              <>
                {Boolean(bumerang) && 
                  <a href={bumerang + redirection} target="_blank" rel="noreferrer">
                    <img src={miniBumerang} alt='bumerang' />
                  </a>
                }
                <InitialsContainer onClick={() => history.push('/profile')}>
                  {user.getInitials() || "Y"}
                </InitialsContainer>
              </>
              ) : (
                <>
                  <LanguageMenu noHome={noHome}/>
                  <ProfileButton onClick={handleProfileClick}>
                    <Icon type={IconType.User} size={28} fill={'currentColor'}/>
                  </ProfileButton>
                </>
              ) 
            ) : (
            <LanguageMenu noHome={noHome}/>
            )
          }

        </HeaderInnerContainer>
      </HeaderBarContainer>
    </>
  )
};