import React, { useContext, useState } from "react";
import { Icon, IconType } from "../icons";
import i18n from "../../../i18n/index";
import { Toggletip } from "../toggletip";
import { Flag, LanguageButton, LanguageList, LanguageOption } from "./LanguageMenuContainers";
import { eventTrack } from "../../../useGaTracker";
import { Lang } from "../../context/app";
import { postError } from "../../shared/utils/postError";
import { AppContext } from "../../context/AppContext";

const mock_languages: Lang[] = ['es', 'ca', 'en', 'de', 'fr', 'it', 'pt'];

const flagTypes = {
  es: IconType.Spanish,
  ca: IconType.Catalan,
  en: IconType.English,
  de: IconType.German,
  fr: IconType.French,
  it: IconType.Italian,
  pt: IconType.Portuguese,
};

const langNames = {
  es: 'Español',
  ca: 'Català',
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Português',
};

type props = {
  noHome?: boolean
  background?: string
}

export const LanguageMenuComponent = ({noHome, background} : props) => {
  const {
    state: {
        restaurant: {
            available_languages
        }
    },
    dispatch
  } = useContext(AppContext);

  const languages = available_languages ? available_languages : mock_languages
  const [language, setLanguage] = useState<Lang>(sessionStorage.getItem("language") as Lang||i18n.language);
  const [showList, setShowList] = useState(false);

  const functionality = sessionStorage.getItem("functionality") || '';
  const path = location.pathname
  const isOrderAndPay = ['order_at_table', 'order_and_collect'].includes(functionality) && path === '/checkout';

  const toggleList = () => setShowList(prev => !prev)

  const onChangeLanguaje = (value: Lang) => {
    i18n.changeLanguage(value);
    setLanguage(value);
    sessionStorage.setItem("language", value);
    dispatch({ type: 'UPDATE_LANG', payload: value });
    eventTrack('change-language', 'change-language', 'change-language')
    // try {
    //   if (isOrderAndPay && noHome) {
    //     window.location.reload();
    //   }
    // } catch(e: any) {
    //   postError(e)
    //   console.log(`onChangeLanguaje exception: ${e.toString()}`);
    // }
  };

  return (
    <Toggletip
      position='bottom'
      alignment='end'
      isVisible={showList}
      onClose={toggleList}
      from="language-menu"
      background={background}
      content={
        <LanguageList>
          {languages.map(lang => {
            return (
              <LanguageOption key={'lang-' + lang} onClick={() => onChangeLanguaje(lang)}>
                <span>{langNames[lang]}</span>
                <Flag isActive={language === lang}>
                  <span><Icon type={IconType.Check}/></span>
                  <Icon type={flagTypes[lang]}/>
                </Flag>
              </LanguageOption>
            );
          })}
        </LanguageList>
      }
    >
      <LanguageButton path={path} onClick={toggleList}>
        <Icon type={flagTypes[language]}/>
      </LanguageButton>
    </Toggletip>
  );
};
