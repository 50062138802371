export enum PopupType {
  None = 'NONE',
  Register = 'REGISTER',
  Splits = 'SPLITS',
  TipsSmall = 'TIPS_SMALL',
  TipsBig = 'TIPS_BIG',
  SplitByAmount = 'SPILT_BY_AMOUNT',
  SplitEvenly = 'SPLIT_EVENLY',
  SplitByProduct = 'SPLIT_BY_PRODUCT',
  Checkout = 'CHECKOUT',
  PaymentsDone = 'PAYMENTS_DONE',
  PaymentDetail = 'PAYMENT_DETAIL',
  PaymentMethods = 'PAYMENT_METHODS',
  Product = 'PRODUCT',
  ProductList = 'PRODUCT_LIST',
  ExitCheckout = 'EXIT_CHECKOUT',
  ClearCart = 'CLEAR_CART',
  ExplanationOP = 'ORDER_AND_PAY',
  Suggestions = 'SUGESTIONS',
  GoogleReview = 'GOOGLE_REVIEW',
  NegativeReview = 'NEGATIVE_REVIEW',
  ThreeMinutes = 'THREE_MINUTES',
  DelaySeconds = 'DELAY_SECONDS',
  SaveCard = 'SAVE_CARD',
  CardSaved = 'CARD_SAVED',
  DeleteCard = 'DELETE_CARD',
  Cart = 'CART',
  PayNowOrLater = 'PAY_NOW_OR_LATER',
  PayLater = 'PAY_LATER',
  ConfirmOrderPayLater = 'CONFIRM_ORDER_PAY_LATER',
  NotInRestaurant = 'NOT_IN_RESTAURANT',
  DishOrderAndComments = 'DISH_ORDER_AND_COMMENTS',
  Welcome = 'WELCOME',
  LoyaltyHistory = 'LOYALTY_HISTORY',
  SplitByMyOrder = 'SPLIT_BY_MY_ORDER',
  BizumPromoInfo = 'BIZUM_PROMO_INFO',
  MasterPromoInfo = 'MASTER_PROMO_INFO',
  LoyaltyTypes = 'LOYALTY_TYPES',
  ZeroSixVouchers = 'ZEROSIX_VOUCHERS',
  ShowToWaiter = 'SHOW_TO_WAITER',
  SwimLineEditProduct = 'SWIMLINE_EDIT_PRODUCT',
  GpsNotActivated = 'GPS_NOT_ACTIVATED',
  OrderSentToKitchen = 'ORDER_SENT_TO_KITCHEN',
  NotProductsInTable = 'NOT_PRODUCTS_IN_TABLE',
  CashbackRegister = 'CASHBACK_REGISTER',
  NoCashbackRegister = 'NO_CASHBACK_REGISTER',
  PostPayRegister = 'POST_PAY_REGISTER',
  CustomerData = 'CUSTOMER_DATA',
  DeliveryAddress = 'DELIVERY_ADDRESS',
  DeliveryNotAvailable = 'DELIVERY_NOT_AVAILABLE',
  NavigateAll = 'NAVIGATE_ALL',
  ProgramOrder = 'PROGRAM_ORDER',
  TicketCart = 'TICKET_CART',
  Ticket = 'TICKET',
  BookingDateSelect = 'BOOKING_DATE_SELECT',
  BookingHourSelect = 'BOOKING_HOUR_SELECT',
  VerificationEmail = 'VERIFICATION_EMAIL',
  InvalidAccount = 'INVALID_ACCOUNT',
  Bumerang = 'BUMERANG',
  OutOfStock = 'OUT_OF_STOCK',
  BumerangSynchronized = 'BUMERANG_SYNCHRONIZED',
  WifiData = 'WIFI_DATA',
  HowToAddCard = 'HOW_TO_ADD_CARD',
  NotEnoughCredits = 'NOT_ENOUGH_CREDITS'
}