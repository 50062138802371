import React, { useContext } from 'react';
import { getProgramsFromStorage } from "../../pages/checkout/functions/getProgramsFromStorage";
import { Request as LoyaltyRequest } from "../../api/loyalty/Loyalty.api";
import { RequestUser } from '../../api/users/Users.api';
import { UserContextDispatchContext } from '../../context/auth/UserContext';
import { AppContext } from '../../context/AppContext';
import { postError } from './postError';
import { CreditDetail, Details } from '../../context/app';

export const useInitialUserData = () => {
  const { dispatch } = useContext(AppContext);
  const { updateUser } = useContext(UserContextDispatchContext);

  const updateInitialUserData = () => {
    try {
      const token = localStorage.getItem("token_yumminn");
      const type = localStorage.getItem("type");
      const restaurantId = Number(sessionStorage.getItem('restaurantId'));
      const { loyaltyProgram } = getProgramsFromStorage();
      const zerosixAvailable = Number(sessionStorage.getItem("zerosix_available")) == 1;
      if (!token || !type) return;

      Promise.all([
        RequestUser.user(token, type),
        LoyaltyRequest.loyaltyCents(token, type),
        LoyaltyRequest.loyaltyPoints(token, type),
        LoyaltyRequest.zerosixClient(token, type),
      ]).then(([userData, centsData, pointsData, zerosixData]: any) => {
        const loyaltyDetails = centsData.data.restaurants.map((restaurant: any) => {
          const loyalty_details = centsData.data.loyalty_details.filter((detail: Details) => detail.restaurant_id === restaurant.restaurant__id);
          return {...restaurant, loyalty_details};
        });
        const creditDetails =  userData.data.credit_data.filter((credit: any) => {
          return credit
        });
        const uniqueIds = new Set();
        const totalCredits = userData.data.credit_data.reduce((total: number, restaurant:any) => {
            if (!uniqueIds.has(restaurant.id)) {
                uniqueIds.add(restaurant.id);
                total += restaurant.balance;
            }
            return total;
        }, 0)
        updateUser({
          isVerified: userData?.data?.is_verified,
          name: userData?.data?.first_name || '',
          lastName: userData?.data?.last_name?.[0] || '',
          email: userData?.data?.email || '',
          phone: userData?.data?.phone || '',
          id: userData?.data?.id || null,
          bumerangUserId: userData?.data?.bumerang_user_id || null,
          points: pointsData.data.total_loyalty_points || 0,
          cashbackCents: centsData.data.restaurants.reduce((totalCashback: number, restaurant: any) => totalCashback + restaurant.cents, 0),
          loyaltyDetails,
          pointsDetails: pointsData.data.points_list || [],
          creditDetails,
          totalCredits: totalCredits
        })
        const restaurantCredits = creditDetails.filter((credit: any) => credit.restaurant_id === restaurantId);
        if (restaurantCredits && restaurantCredits.length > 0) {    
          const totalBalance = restaurantCredits.reduce((acc: number, credit: any) => acc + credit.balance, 0);
          dispatch({
            type: 'UPDATE_WALLET_INFO',
            payload: {
                balanceCredit: totalBalance,
            }
          })
        }
        restaurantId && loyaltyProgram && dispatch({
          type: 'UPDATE_CHECKOUT',
          payload: {
            availableLoyaltyCents: centsData?.data?.restaurants?.find((restaurant: any) => restaurant.restaurant__id === restaurantId)?.cents || 0,
          }
        });
        restaurantId && dispatch({
          type: 'UPDATE_CHECKOUT',
          payload: {
            availableCreditCents: creditDetails.reduce(function(prev: CreditDetail, current: CreditDetail) {
              return prev.balance > current.balance ? prev.balance : current.balance || 0;
            }, 0),
            credit: creditDetails.reduce(function(prev: CreditDetail, current: CreditDetail) {
              return prev.balance > current.balance ? prev.name : current.name || '';
            }, '')
          }
        });

        restaurantId && zerosixAvailable && dispatch({
          type: 'UPDATE_CHECKOUT',
          payload: {
            zeroSixInfo: {points_balance: zerosixData.data?.available_vouchers?.find((restaurant: any) => restaurant.restaurant.id === restaurantId)?.points_balance,
              points_total: zerosixData.data?.available_vouchers?.find((restaurant: any) => restaurant.restaurant.id === restaurantId)?.total_points,
              vouchers:  zerosixData.data?.available_vouchers.length > 0 ? (zerosixData.data?.available_vouchers?.find((restaurant: any) => restaurant.restaurant.id === restaurantId)?.vouchers.map((voucher: any) => 
                ({name: voucher.name,
                code: voucher.code,
                value: Number(voucher.value),
                is_percentage: voucher.unit != "Euros",
                expiration: voucher.expiration,
                min_amount: Number(voucher.minimum_amount)
            }))) : []
            },
            zeroSixSelectedVoucher: {
              name: "",
              code: "",
              value: 0,
              is_percentage: false,
              expiration: "",
              min_amount: 0
            }
          }
        })
      }).catch((error: any) => {
        console.log({error})
        postError(error);
      })
    } catch (error: any) {
      console.error({ error });
      postError(error);
    }
  };

  return updateInitialUserData;
}